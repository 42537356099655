


.reviewpage1 {
    background-color: white;

    .jobInternshipTitle{
        font-size: 26px ;
        @media screen and (max-width: 768px) {
            font-size: 22px ;
        }
    }   
    .r1 {
        .sectionn11 {
            background: white;
            border-radius: 6px;
          //  border: solid 1px rgb(214, 214, 214);
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 15px;
            padding-bottom: 15px;
            margin: 30px;
            max-width: 950px;
           margin-left: auto;
           margin-right: auto;

            .post {
                background: #ffffff;
                border-radius: 6px;
                padding-left: 8px;
                padding-right: px;
                padding-top: 3px;
                padding-bottom: 3px;
                font-size: 13px;
                font-weight: bold;
                color: #4B92C8;
                border-color: lightskyblue;


            }

          

    


            .post1234 {
                background: #f5f5f5;
                border-radius: 20px;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 4px;
                padding-bottom: 4px;
                font-size: 14px;
                color: #535353;
                display: inline-block; /* Adjust width according to content */
            }

            .heeading {
                text-transform: uppercase;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 15px;
                letter-spacing: 0.5px;
                color: #7e7e7e;
            }

            .head {
                margin-top: -3px;
                margin-left: 5px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px !important;
                line-height: 17px;
                color: #303030;
            }

            .content {
                .wfm1 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #303030;

                }

                

                .lastbutton {
                    background: #2ba4f5;
                    border-radius: 5px;
                    color: white;
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    font-size: 16px;
                    font-weight: 500;

                    &:hover {
                        background: #3097dc;
                        color: white; 
                    }

                }
            }

            .title {
                .icon {
                    font-size: 12px;

                    i {
                        color: #0077E4;
                    }
                }

                .btntouch {
                    background: #FFFAEB;
                    border-radius: 15px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 19px;
                    color: #9E7357;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                .btntouch:hover {
                    background-color: #9E7357;
                    color: #FFFAEB;
                }
            }

            .companynamee {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #313131;
                line-height: 25px;
            }
        }

        .section2 {
            border: 1px solid #B1B1B1;
            background: #FFFFFF;
            border-radius: 15px;
            box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.26);

            .reviewpagebtn {
                .btnrevie {
                    background: #FFFFFF;
                    border: 1px solid #C1C1C1;
                    border-radius: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .information {
                .Internship {
                    border-bottom: 1px solid #B6B6B6;
                    padding-bottom: 7px;

                    .internname {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 19px;
                        text-transform: uppercase;
                        color: #3D3D3D;
                    }
                }

                .Education {
                    border-bottom: 1px solid #B6B6B6;
                    padding-bottom: 7px;
                }

                .titlee {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;
                    text-transform: uppercase;
                    color: #6D6D6D;
                }

                .eduname {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #3D3D3D;
                }

                .clgname,
                .schlname,
                .year,
                .board,
                .percantage {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 22px;
                    color: #5F5F5F;
                }
            }

            .heading {
                background: #D3EAFF;
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 15px;
                padding-bottom: 15px;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;

                .aplied {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 19px;

                    color: #676767;
                }
            }

            .cover {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: #3D3D3D;
            }

            .hire {
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: #3D3D3D;
            }

            .para {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                color: #5F5F5F;

            }
         

        }
    }
}


 .wfm123 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #535353;

}

.post1 {
    background: #eeeeee;
    border-radius: 6px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13px;
    color: #696969;
    cursor: auto ;

}
.responsive-col {
    width: 75%; /* col-9 equivalent */
  }
  
  @media (max-width: 576px) {
    .responsive-col {
      width: 66.6667%; /* col-8 equivalent */
    }
  }