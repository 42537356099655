.message-student {
  // padding: 2rem 0;
  background-color: rgba(132, 132, 132, 0.1);
  .chat-box {
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;
    display: flex;
    height: 600px;
    background: #ffffff;
    border: 1px solid #cbcbcb;

    .recent-chats {
      display: flex;
      flex-direction: column;
      width: 33%;

      .tabs {
        height: 68px;
        border-bottom: 1px solid #cbcbcb;
        background: #ffffff;
        display: flex;
        align-items: center;
        gap: 2rem;

        .btn1 {
          background: #4b92c8;
          border-radius: 20px;
          color: white;
          padding-left: 20px;
          padding-right: 20px;
          position: absolute;
          z-index: 1;
          left: 15%;
        }

        .btn2 {
          background: #ffffff;
          border: 1px solid #dbdbdb;
          border-radius: 30px;
          padding-left: 35px;
          padding-right: 35px;
          margin-left: 26%;
        }
      }

      .chats {
        background: #ffffff;
        height: 100%;
        overflow: auto;

        .chat {
          padding: 2rem;
          border-bottom: 1px solid #d6d6d6;
          border-right: 1px solid #d6d6d6;

          position: relative;
          transition: 0.3s ease-out;
          display: block;
          text-decoration: none;

          &:hover {
            background-color: #ccc;
          }

          .date {
            position: absolute;
            top: 2rem;
            right: 2.5rem;
            font-size: 1.3rem;
          }

          .status {
            background: #d3eaff;
            border-radius: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            padding-left: 18px;
            padding-right: 18px;
            padding-top: 2px;
            padding-bottom: 2px;

            color: #585858;
          }

          .title,
          .message {
            display: block;
          }

          .title {
            font-size: 1.5rem;
          }

          .message {
            margin-top: 1rem;
            font-size: 1.4rem;
          }
        }
      }
    }

    .chatroom {
      width: 67%;
      background: #ffffff;

      // display: flex;
      // flex-direction: column;

      .sender-area {
        height: 60px;
        background: #ffffff;
        padding: 1rem;
        border-left: 1px solid #cbcbcb;
        border-bottom: 1px solid #cbcbcb;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .profile {
          // strong,
          // span {
          //     display: block;
          // }

          // strong {
          //     font-size: 1.5rem;
          // }

          // span {
          //     margin-top: .5rem;
          //     font-size: 1.3rem;
          // }
        }
      }

      .message-section {
        display: flex;
        flex-direction: column;
        height: 85%;
      }

      .conversations {
        padding: 1rem;
        height: 100%;
        overflow: auto;

        .date-time {
          padding: 1rem;
          text-align: center;
          font-size: 1.4rem;
        }

        .conversation {
          display: flex;
          margin: 2rem;

          p {
            border: 1px solid #d6d6d6;
            max-width: 70%;
            padding: 1rem;
            border-radius: 15px;
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: 0.3px;
          }

          &.left {
            justify-content: flex-start;

            p {
              background: #f4f4f4;
              box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }
          }

          &.right {
            justify-content: flex-end;

            p {
              background: #d3eaff;
              box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }
          }
        }
      }

      .send-message-box {
        display: flex;
        padding: 2rem;
        align-items: center;
        gap: 2rem;
        border-top: 1px solid #d6d6d6;
        .btnsend {
          background: #4b92c8;
          border-radius: 5px;
          color: white;
          padding-left: 20px;
          padding-right: 20px;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 19px;
          color: #ffffff;
        }

        input {
          width: 100%;
          padding: 1rem 1.3rem;
          border-radius: 15px;
          outline: none;
          border: 1px solid #d6d6d6;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .bt {
    display: none;
  }
}
