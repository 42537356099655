.section {
    margin: 10px auto;
    height: auto;
    width: 100%;
}



.select-tag {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+/Edge */
    user-select: none; /* Standard syntax */
  }



.banner {
    margin: 40px 0px;
    padding: 11px;
    font-size: 13px;
    line-height: 1.5;
    font-weight: 400;
    background-color: #eafcff;
    border: 1px solid #8feafe;
    border-radius: 6px;
    color: #006bc2;
}

.sub-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;


    .title {
        font-size: 22px;
        font-weight: 500;
    }

    .fields {
        padding: 20px 18px;
        background-color: white;
        border: 1px solid #DDD;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .details {
            display: flex;
            flex-direction: column;
            gap: 0px;

            .input-text {
                padding: 8px;
                outline: none !important;
                border: 1px solid #DDD;
                margin-top: 4px;
                border-radius: 4px;
            }

            .input-text:focus {
                border: 1px solid #00a5ec;
            }

            .input-text::placeholder {
                font-size: 14px;
                color: #8A8A8A;
            }

            .select-tag::placeholder {
                font-size: 14px;
                color: #8A8A8A;

            }
        }

        .take-date {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 20px;
        }

        .take-date> :first-child {
            width: 30px;
            color: #333;
            font-size: 14px;
        }

        .take-date input {
            width: 300px;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 8px;
            font-size: 14px;
            color: #333;
            outline: none;
        }

        .take-date input:hover,
        .take-date input:focus {
            border: 1px solid #00a5ec;
        }

        .parent-dropdown2 {
            position: relative;
            padding: 10px 8px;
            width: 100%;
            outline: none;
            border: 1px solid #eee;
            border-radius: 4px;
            font-size: 14px;
            color: #484848;
        }

        .cross {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            padding: 0px 6px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .parent-dropdown2:hover,
        .parent-dropdown2:focus {
            border: 1px solid #00a5ec;
        }

        .dropdown {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 3px;
            background: white;
            height: fit-content;
            max-height: 200px;
            position: absolute;
            width: 100%;
            top: 44px;
            display: flex;
            flex-direction: column;
            z-index: 100;
            overflow-y: auto;
            float: left;
            text-align: left;
            color: #212529;
            font-weight: 400;
        }

        .dropdown div {
            padding: 6px 12px;
            font-weight: 400;
            cursor: pointer;
            font-size: 14px;
            color: #8A8A8A;
        }



        .dropdown>div:hover {
            background-color: #eafcff;
            color: #008bdc;
            cursor: pointer;
        }
    }

    .sub-title {
        font-size: 14px;
        font: #333;
    }
}

.input-text {
    padding: 8px;
    outline: none !important;
    border: 1px solid #ddd;
    margin-top: 4px;
}

.input-text:focus {
    border: 1px solid #00a5ec;
}

.input-text::placeholder {
    font-size: 14px;
    color: #8A8A8A;
}

.select-tag::placeholder {
    font-size: 14px;
    color: #8A8A8A;
}

.check-input {
    width: 16px;
    height: 16px;
    outline: none;
    border: 1px solid #DDD;
    cursor: pointer;
}

.check-input:focus {
    border: 1px solid #00a5ec;
}


.check-labels {
    font: #484848;
    font-weight: 400;
    font-size: 14px;
}


.submit-btn {
    background-color: #00a5ec;
    border: 1px solid #00a5ec;
    outline: none;
    transition: 0.3s;
    color: white;
    padding: 3px 6px;
    margin-left: 8px;
    border-radius: 4px;
}

.submit-btn:hover {
    background-color: #048cc7;

}

.submit-btn:active {
    background-color: black;

}

.popup-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 2000;
    background: white;
    position: relative;

    .close-button123 {
        position: absolute;
        color: black;
        top: 5px;
        right: 10px;
        cursor: pointer;
        font-weight: 600;
        font-size: 20px;
    }

    .success-message {
        text-align: center;
        font-weight: 500;
        font-size: 17px;
        margin-top: 30px;
        line-height: 25px;
        color: rgb(53, 53, 53);

    }

    .success-message1 {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height:22px;
        color: rgb(102, 102, 102);
        
    }

    .submit-btn123 {
        border: none;
        outline: none;
        background-color: white;
        color: #00a5ec;
        font-weight: 600;
        font-size: 20px;
        transition: all 0.3s;
    }

    .submit-btn123:hover {
        color: #006bc2;
    }
}


.popup-containernew {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 2000;
    background: white;
    position: relative;

    .close-button12345 {
        position: absolute;
        color: black;
        top: 5px;
        right: 10px;
        cursor: pointer;
        font-weight: 600;
        font-size: 20px;
    }

    .success-message {
        text-align: center;
        font-weight: 500;
        font-size: 17px;
        margin-top: 30px;
        line-height: 25px;
        color: rgb(53, 53, 53);

    }

    .success-message1 {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height:22px;
        color: rgb(102, 102, 102);
        
    }

    .submit-btn12345 {
        border: none;
        outline: none;
        background-color: white;
        color: #00a5ec;
        font-weight: 600;
        font-size: 20px;
        transition: all 0.3s;
        padding: 10px;
    }

    .submit-btn12345:hover {
        color: white;
        background-color: #00a5ec;
        border-radius: 5px;
    }
}


.question {
    font-weight: 500;
    color: #333;
    font-size: 14px;
}

.answer {
    font-weight: 400;
    color: #484848;
    font-size: 14px;
}

.instruction {
    font-weight: 400;
    color: #8A8A8A;
    font-size: 14px;
}

.ask {
    transition: all 0.3s ease-in-out;
}

.show-question {
    height: 100%;
    opacity: 100%;
}

.hide-question {
    height: 0;
    opacity: 0;
    display: none;
}

@media screen and (min-width: 692px) {
    .popup-container {
        width: 75%;
    }

    .popup-containernew{
        width: 75%;
    }
}

@media screen and (min-width: 992px) {
    .section {
        width: 65%;
    }

    .popup-container {
        width: 40%;
    }
    .popup-containernew{
        width: 40%;
    }
}
.selectcomponentresponsive{
    width: 25% !important;
}

.oppormobileresponsive{
    display:flex !important;
    align-items:center !important;
    gap:3rem !important;

    @media screen and (max-width:450px){
        gap:1rem !important;
    }
}

@media screen and (max-width:450px){
    .selectcomponentresponsive{
        width:58% !important;
    }
    .oppormobileresponsive{
        display: flex;
        flex-direction:column !important;
        align-items:start !important;
    }
    .take-date input{
        width:225px !important;
    }
}

