@import "../../../_variables";


inp:focus,
a:focus {
    outline: none;
}

.signup-container {
    background-color: $TRANSPARENT_DEFAULT_BLUE;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 95px);
}

.studentsignup {
    background: #FFFFFF;
    border-radius: 20px;
    align-items: center;
    max-width: 1320px;

    .title {

        padding-top: 50px;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 35px;
        color: #1A3447;
    }

    .para {
        padding-top: 20px;
        padding-right: 290px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        color: rgba(26, 52, 71, 0.7);
    }

    .avtar {
        width: 17.77px;
        height: 17.77px;
        background: #FFFFFF;
        border-radius: 591.887px;
    }

    .parahike {
        font-style: normal;
        font-weight: 500;
        font-size: 12.0722px;
        line-height: 12px;
        color: rgba(26, 52, 71, 0.7);
    }

    .imagesection {
        img {
            width: 258px;
            height: 265.55px;
        }
    }
}

.studentform {

    .form-box {
        .form {
            background-color: white;
            padding: 2rem 3rem;
            box-shadow: 0px 4px 4px 0px #00000040;
            max-width: 800px;
            box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
            border-radius: 20px;
            @media screen and (max-width: 600px) {
                padding: 1rem 1.5rem;
            }
            .login-btn {
                color: $PRIMARY_COLOR;
                margin: 1.5rem 0;
                text-align: center;
                display: block;
                font-weight: 500;
            }

            .title {
                font-style: normal;
                font-weight: 500;
                font-size: 30.5172px;
                margin-top: -40px;
            }

            .label {
                font-weight: 500;
                padding-bottom: 2rem;
                margin-top: -22px;
                margin-left: 18px;

                a {
                    color: $PRIMARY_COLOR;
                }
            }

            .btnsign {
                background: #4B92C8;
                border-radius: 4.23851px;
                color: white;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #357CA5;
                }
            }

            .btn-google {
                button {
                    border: 1px solid #000000;
                    background: white;
                    width: 100%;
                    padding: 5px;
                    font-size: 1.3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    transition: background-color 0.3s ease;
                    background-color: white;
                }
            }

                .name-box {
                    display: flex;
                    gap: 2rem;
                }

                button {
                    border-radius: 5px;
                }

                * {
                    font-size: 1.4rem;
                }

            }
        }
    }


    @media screen and (max-width: 994px) {
        .para12345 {
            display: none;
        }

        
    }


    
    @media screen and (max-width: 756px) {
        .hideMob {
            display: none;
        }

        
    }



    @media screen and (max-width: 520px) {
        .student-signup {
            padding: 1.5rem 1rem;
        }
    }

    @media screen and (max-width: 620px) {
        .studentsignup {
            .title {
                padding-right: 1px;
            }

            .para12345 {
                padding-right: 1px;
            }
        }
    }