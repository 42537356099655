ul li {
  list-style: none;
  text-decoration: none;
}

ul li a {
  color: #484848;
  text-decoration: none;
  padding-left: 0;
  display: block;
  // margin-top: 10px;
  // margin-bottom: 10px;
  font-weight: 500;
}

ul li a:hover {
  text-decoration: none;
  color: #008bdc;
}

.hover-item {
  font-weight: 500;
  color: #484848;
}
.hover-item:hover {
  color: #008bdc;
}
