*{
    padding: 0;
    text-decoration: none;
    
}
.search{
   // background-color: #0077E41F;
   background-color: rgb(235, 246, 255);

   
    border-radius: 5px;
    Select{
        border-radius: 3px;
    }
}
.firstsection{
    padding-bottom: 90px;
}
.search .row{
padding: 30%;
}
.search .btn{
    background: #4B92C8;
    border-radius: 15px;
    color: white;
}

.search .btn:hover {
    background: #357bad; /* Change the background color on hover */
}


.select{
   outline: none;
   border: none;
}

.font-weight-bold{
   margin-left: 15px;
    
}



@media screen and (max-width: 620px) {
    .first-select{
        margin-bottom: 20px;
    }
    .second-select{
        margin-bottom: 25px;
        
    }
    .font-weight-bold{
        text-align: center;
        margin-left: 0px;
        
    }
}


