@import "../../../_variables";

.post-job-form {
    padding: 4rem 0;

    .job-form {
        max-width: 560px;
        margin: 0 auto;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 3rem;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);

        .title {
            text-align: center;
            padding-bottom: 2rem;
        }

        .chip {
            font-size: 1.3rem;
            font-weight: 600;
            color: #333;
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .radio-box {
            display: grid;
            margin-top: 2rem;
            grid-template-columns: auto auto auto;
            gap: 2rem;

            label.strong {
                display: block;
                font-weight: 600;
                margin-bottom: 2rem;
            }

            .checks {
                margin: 1rem 0;

                label {
                    padding-left: 1rem;
                }
            }
        }

        .inputs {
            strong.label {
                display: block;
                padding: 1rem 0;
                padding-top: 3rem;
            }
        }

        .input-box {
            display: flex;
            padding: 1rem 0;
            align-items: center;

            label {
                width: 250px;
                font-size: 1.5rem;
                font-weight: 500;
            }

            input,
            select {
                width: 100%;
                padding: .8rem 1.2rem;
                outline: none;
                border: 1px solid rgba(0, 0, 0, 0.2);
            }
            
            select {
                padding: .8rem 1rem;
            }

            input[type="radio"] {
                width: auto;
            }

            .year {
                display: grid;
                grid-template-columns: 60px 42px;
                align-items: center;
                gap: .6rem;

                label {
                    font-size: 1.4rem;
                }
            }
        }

        .move-page-buttons {
            padding: 2rem 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                display: flex;
                gap: .5rem;
                align-items: center;
            }
        }
    }

    .stipend-box {
        .my-radio {
            padding: 2rem 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            label.label {
                font-weight: 500;
            }

            .input {
                display: flex;
                gap: 1rem;
                align-items: center;
            }
        }
    }

    .cover_label {
        display: block;
        padding-bottom: 2rem;
        font-size: 1.4rem;
    }

    .cover-letter-input-box {
        flex-direction: column;
        align-items: flex-start !important;
        width: 100%;

        label {
            padding-bottom: 1rem;
            font-size: 2.2rem;
        }

        textarea {
            width: 100%;
            height: 100px;
            outline: none;
            padding: .8rem 1.2rem;
        }
    }

    .add-assesment {
        font-size: 1.4rem;
        padding: 1rem 0;
        color: $PRIMARY_COLOR;
        font-weight: 500;
        cursor: pointer;
    }

    .help-center-message {
        font-size: 1.4rem;
        padding: 3rem 0;
        font-weight: 500;

        a {
            color: $PRIMARY_COLOR;
        }
    }
}

.job-draft-modal-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);

    .modal {
        background-color: white;
        padding: 3rem;
        border-radius: 15px;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
    }
}