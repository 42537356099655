


.reviewpage {
    .r1 {
        .sectionn1 {

            box-shadow: 3px 0 8px -3px rgba(233, 233, 250, 0.808),   /* Right */
            -3px 0 8px -3px rgba(233, 233, 250, 0.808),  /* Left */
            0 3px 8px -3px rgba(233, 233, 250, 0.808),  /* Bottom */
            0 -3px 8px -3px rgba(233, 233, 250, 0.808);
            background: #FFFFFF;
            border-radius: 15px;
            border: "none";
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 15px;
            padding-bottom: 15px;
            margin: 30px;

            .post {
                background: #ffffff;
                border-radius: 6px;
                padding-left: 8px;
                padding-right: px;
                padding-top: 3px;
                padding-bottom: 3px;
                font-size: 13px;
                font-weight: bold;
                color: #4B92C8;
                border-color: lightskyblue;


            }

            .heeading {
                text-transform: uppercase;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 17px;
                color: #8C8C8C;
            }

            .head {
                margin-top: -3px;
                margin-left: 5px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #4F4F4F;
            }

            .content {
                .wfm {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #484848;

                }

                .lastbutton {
                    background: #4B92C8;
                    border-radius: 10px;
                    color: white;
                    padding-left: 20px;
                    padding-right: 20px;

                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 12px;

                    &:hover {
                        background-color: #357CA5; 
                        color: white; 
                    }

                }
            }

            .title {
                .icon {
                    font-size: 12px;

                    i {
                        color: #0077E4;
                    }
                }

                .btntouch {
                    background: #FFFAEB;
                    border-radius: 15px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 19px;
                    color: #9E7357;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                .btntouch:hover {
                    background-color: #9E7357;
                    color: #FFFAEB;
                }
            }

            .companynamee {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #313131;
                line-height: 25px;
            }
        }

        .section2 {
            border: 1px solid #B1B1B1;
            background: #FFFFFF;
            border-radius: 15px;
            box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.26);

            .reviewpagebtn {
                .btnrevie {
                    background: #FFFFFF;
                    border: 1px solid #C1C1C1;
                    border-radius: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .information {
                .Internship {
                    border-bottom: 1px solid #B6B6B6;
                    padding-bottom: 7px;

                    .internname {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 19px;
                        text-transform: uppercase;
                        color: #3D3D3D;
                    }
                }

                .Education {
                    border-bottom: 1px solid #B6B6B6;
                    padding-bottom: 7px;
                }

                .titlee {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;
                    text-transform: uppercase;
                    color: #6D6D6D;
                }

                .eduname {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #3D3D3D;
                }

                .clgname,
                .schlname,
                .year,
                .board,
                .percantage {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 22px;
                    color: #5F5F5F;
                }
            }

            .heading {
                background: #D3EAFF;
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 15px;
                padding-bottom: 15px;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;

                .aplied {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 19px;

                    color: #676767;
                }
            }

            .cover {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: #3D3D3D;
            }

            .hire {
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: #3D3D3D;
            }

            .para {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                color: #5F5F5F;

            }
         

        }
    }
}