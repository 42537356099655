.editResumeBg{
  background-color: white;
}


.btn-info01{
    border-radius: 8px;
    height: 40px;
    font-size: 13px;
    background-color: #4ea6cf;
    color: white;
    transition: background-color 0.3s;

    &:hover {
        background-color: #4092b8;
        color: white;
    }
}

.text-right {
    //style={{ display: "flex", justifyContent: "flex-end" }}>
    display: flex;
    justify-content: flex-end;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.ResumeBackBtn{
  margin-top: 40px;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  color: #2092c7;
  font-size: 14px;
  cursor: pointer;
}


.section31 {
  background: #FFFFFF;
  border: 1px solid rgb(241, 241, 241);
  border-radius: 6px;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: none;
  padding-top: 30px;
  padding-bottom:0px;
  padding-left: 40px;
  padding-right: 40px;


  .personalDetailsName{
    font-size: 29px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.dropdown-divider1{

  height: 0.05px;
}



.custom-modal-dialog {
  max-width: 650px; 
}

.ModelInput{
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
}

.saveUpdate{
background-color: #1e9cf0;
color: white;
border: none;
border-radius: 5px;
padding-top: 12px;
padding-bottom: 12px;
padding-left: 50px;
padding-right: 50px;
font-weight: 500;
margin-right: 15px;
margin-bottom: 12px;
}

.saveUpdate:hover{
background-color: #2092c7;
}


@media screen and (max-width: 1298px) {
  .ResumeBackBtn{
    margin-left: 40px;
  }
}


@media screen and (max-width: 700px) {
  .section31 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .ResumeBackBtn{
    margin-left: 20px;
    margin-top: 10px;
  }
}


@media screen and (max-width: 450px) {
  .resumePeronalDetailpencilicon {
    margin-left: 3rem !important;
    margin-right: 1rem !important;
  }
}

@media screen and (max-width: 450px) {
  .mobile-flex-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mobile-column-to-increase-width{
    min-width: 310px;
  }
}
