.plansection {
    padding: 20px;
    background: linear-gradient(90deg, #377BFF -0.8%, #1C93FF 100%);

    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 27px;
        line-height: 39px;
        color: #FFFFFF;

    }

    .plan1 {
        background-color: white;
        padding-top: 50px;
        padding-bottom: 50px;

    }

    .Business {
        padding-top: 50px;
        padding-bottom: 50px;
        background: #2C3140;
        border: 1px solid #DBDBDB;
        border-radius: 4px;

        .type {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: white;
        }

        .rate {
            font-style: normal;
            font-weight: 800;
            font-size: 62px;
            line-height: 78px;
            text-align: center;
            color: white;
        }

        .para {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #DDDDDD;
        }

        .price {
            padding-left: 50px;
            padding-right: 50px;
            padding-top: 15px;
            padding-bottom: 15px;
            background: #1465FA;
            border: 3px solid #1465FA;
            border-radius: 3px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: white;
        }
    }

    .type {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #000000;
    }

    .rate {
        font-style: normal;
        font-weight: 800;
        font-size: 62px;
        line-height: 78px;
        text-align: center;
        color: #000000;
    }

    .para {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        text-align: center;

        color: #747474;
    }

    .price {
        border: 3px solid #1465FA;
        border-radius: 3px;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: white;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #1465FA;
    }

    .bottmborder {
        border: 1px solid #DDDDDD;
        width: 40%;
        margin-left: 93px;
    
    }
}

.resentOTP{
    color: gray;
}

.resentOTP:hover{
    color: #2874A6;
}