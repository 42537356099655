@import "../../../_variables";

.job-application-box {
    // padding: 4rem 0;
    gap: 2rem;
}

.my-applications {
    .job-applications {
        width: 100%;

        .job-card {
            margin: 3rem 0;
            padding: 2rem;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            box-shadow: 1px 2px 20px rgba(151, 151, 151, 0.2);
            
            .company-title {
                padding: 1rem 0;
                font-size: 1.8rem;
            }

            .job-title {
                font-weight: 500;
                color: #8A8A8A;
                padding-bottom: 2.5rem;
                font-size: 1.5rem;
            }

            .info-box {
                display: flex;
                gap: 3rem;
                padding-bottom: 3rem;

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: .8rem;

                    span {
                        font-size: 1.4rem;
                    }
                }
            }

            .options {
                display: flex;
                justify-content: flex-end;
                gap: 2rem;
                padding-top: 1.5rem;
                border-top: 1px solid rgba(0, 0, 0, 0.1);

                * {
                    color: $PRIMARY_COLOR;
                }
            }
        }
    }
}

@media screen and (min-width: 640px) {
    .my-applications {
        .job-applications {
            width: 600px;
        }
    }
}

@media screen and (max-width: 480px) {
    .job-application-box {
        padding: 3rem 1rem;
    }
}