.footer{
    background-color: white;
    .socialicon{
        i{
            font-size: 17px;
            border: 1px solid black;
            padding: 7px;
            padding-left: 9px;
            padding-right: 9px;
            border-radius: 20px;
            overflow-x: hidden;
        }
        i:hover{
            background-color: black;
            color: white;
        }
    }
    p{
        font-size: 13px;
    }
    h4{
        font-size: 15px;
        font-weight: 500;

    }
    .links{
        a{
            text-decoration: none;
            font-size: 14px;
            color: rgb(125, 124, 124);
        }:hover{
            color: rgb(79, 79, 79);
        }
    }
    strong {
        font-weight: 500;
        font-size: 1.4rem;
    }
    
    img {
        height: 80px;
        width: 200px;
    }
}
.app-footer {
    border-top: 1px solid #33333365;
    box-shadow: 1px 2px 2px #33333364;

    .expanded-footer {
        display: flex;
        padding: 2rem;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;

        .links {
            a {
                padding: .5rem;
            }
        }

        .company {
            display: flex;
            flex-direction: column;
            // gap: 1rem;
            align-items: center;
            overflow-x: hidden;
          
            strong {
                font-weight: 500;
                font-size: 1.4rem;
            }
            
            img {
                height: 50px;
                width: auto;
            }
        }
    }
    
    .main-footer {
        background-color: #4B92C8;
        display: flex;
        justify-content: center;
        gap: 2rem;
        padding: 2rem;
        overflow-x: hidden;
        
        .item {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            
            img {
                width: 20px;
                height: 20px;
            }
            
            * {
                color: white;
            }
        }
    }
}


.footerTab{
    min-width: 200px;
}

@media screen and (min-width: 620px) {
    
    .app-footer {
        .expanded-footer {
            padding: 2rem 5rem;
            
            .links {
                a {
                    padding: 1rem;
                }
            }
        }
        
        .main-footer {
            gap: 8rem;
        }
    }
}

@media screen and (max-width: 620px) {
    .footer{
        text-align: center;
    }
    .app-footer {
        .main-footer {
            * {
                font-size: 1.2rem;
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .app-footer {
        .expanded-footer {
            flex-direction: column-reverse;
            gap: 3rem;
            padding: 2rem 1rem;
        }
    }
}