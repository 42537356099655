@import "../../../_variables";

.organization-details {
    padding: 4rem 1rem;

    .organization-form {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);

        .title {
            text-align: center;
        }

        .form-box {
            padding: 3rem 0;

            .input-box {
                padding: 1rem 0;
                display: flex;
                justify-content: space-between;

                label {
                    font-weight: 500;
                }

                input,
                textarea {
                    width: 270px;
                    padding: .8rem 1.2rem;
                    outline: none;
                }

                textarea {
                    height: 80px;
                }
            }
        }

        input[type="text"], textarea {
            width: 270px;
            padding: .8rem 1.2rem;
            outline: none;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            transition: .3s;

            &:hover, &:focus {
                border-color: $PRIMARY_COLOR;
            }
        }
        
        
        .check-input-box {
            border: 1px solid rgba(49, 49, 49, 0.2);
            padding: 3rem 0;
            margin-top: 3rem;
            border-radius: 10px;
            margin-left: auto;
            box-shadow: 1px 2px 10px rgba(185, 185, 185, 0.2);
            margin-right: auto;
            padding: 1rem;
            width: 75%;

            .input-box {
                padding: 1rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                // justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 580px) {
    .organization-details {
        .organization-form {
            width: 100%;
        }
    }
}

@media screen and (max-width: 480px) {
    .organization-details {
        .organization-form {
            .form-box {
                .input-box {
                    flex-direction: column;
                    gap: 1rem;

                    input,
                    textarea {
                        width: 100%;
                    }
                }
            }

            .check-input-box {
                width: 100%;
            }
        }
    }
}