$BACKGROUND_BLUE: #0077E41F;

.home-categories-box {
 //  background-color: $BACKGROUND_BLUE;
  background-color: rgb(244, 250, 255);


    padding: 3rem;

    .box {
        transform: translateY(-120px);

    }

    .job-options-box {
        display: flex;
        flex-wrap: wrap;
        // grid-template-columns: 200px 200px 200px 200px;
        gap: 20px;
        justify-content: center;

        * {
            color: black;
            text-decoration: none;
        }

        p {
            font-weight: 500;
        }

        .option {
            box-sizing: border-box;
        //  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; 
        box-shadow: 3px 0 8px -3px rgb(223, 236, 250),   /* Right */
               -3px 0 8px -3px rgb(223, 236, 250),  /* Left */
               0 3px 8px -3px rgb(223, 236, 250),  /* Bottom */
               0 -3px 8px -3px rgb(223, 236, 250);  /* Top */
         // border: 1px solid #0077E4;
            border-radius: 15px;
            padding: 1rem;
            display: flex;
            width: 190px;
            height: 190px;
            flex-direction: column;
            gap: 2rem;
            align-items: center;
            background-color: white;
            margin: 25px;

            &:hover {
                background-color: white; // Change the background color on hover
                color: black; // Change the text color on hover
                transform: scale(1.05); // Scale the box slightly on hover (optional)
                transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease; // Add a smooth transition effect
            }

            img {
                width: 120px;
                height: 120px;
            }
        }
    }

    .popular-job-box {
        margin: 7rem 0;


        .title {
            padding: 1rem 0;
            padding-bottom: 3rem;
            text-align: center;
        }

        .categories {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            justify-content: center;
            align-items: center;


            .category {
                display: flex;
                align-items: center;
                flex-direction: column;
                /* Stack content vertically */
                justify-content: center;
                /* Center vertically */
                align-items: center;
                /* Center horizontally */
                background-color: white;
                padding: 2rem;
              //  border: 1px solid #0d6efd52;
                border-radius: 10px;
               // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                width: 230px;
                height: 100px;
               // border: 1px solid #82c3ff;
               // box-shadow: 0px 4px 4px rgba(127, 160, 252, 0.25);
               box-shadow: 3px 0 8px -3px rgb(223, 236, 250),   /* Right */
               -3px 0 8px -3px rgb(223, 236, 250),  /* Left */
               0 3px 8px -3px rgb(223, 236, 250),  /* Bottom */
               0 -3px 8px -3px rgb(223, 236, 250);  /* Top */
               color: rgb(61, 61, 61);

                &:hover {
                    background-color: white; // Change the background color on hover
                    color: black; // Change the text color on hover
                    transform: scale(1.05); // Scale the box slightly on hover (optional)
                    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease; // Add a smooth transition effect
                }

                .img-box {
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;
                }

                img {
                    width: auto;
                    height: 30px;
                }
            }
        }
    }
}

.categories01 {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-items: center;


    .category01 {
        display: flex;
        align-items: center;
        flex-direction: column;
        /* Stack content vertically */
        justify-content: center;
        /* Center vertically */
        align-items: center;
        /* Center horizontally */
        background-color: white;
        padding-top: 10px;
        padding-left: 30px;
        padding-right: 30px;
        // border: 1px solid #82c3ff;
        border-radius: 10px;
       // box-shadow: 0px 4px 4px rgba(127, 160, 252, 0.25);
       width: 230px;
       // height: 100px;
      // color: #0077E4;
      box-shadow: 3px 0 8px -3px rgb(223, 236, 250),   /* Right */
      -3px 0 8px -3px rgb(223, 236, 250),  /* Left */
      0 3px 8px -3px rgb(223, 236, 250),  /* Bottom */
      0 -3px 8px -3px rgb(223, 236, 250);  /* Top */
       color: rgb(61, 61, 61);

        &:hover {
            background-color: white; // Change the background color on hover
            color: black; // Change the text color on hover
            transform: scale(1.05); // Scale the box slightly on hover (optional)
            transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease; // Add a smooth transition effect
        }

        .img-box {
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
        }

        img {
            width: auto;
            height: 30px;
        }
    }
}


@media screen and (min-width: 720px) {
    .home-categories-box {
        padding: 3rem 7rem;
    }
}

@media screen and (min-width: 1180px) {
    .home-categories-box {
        .popular-job-box {
         //   .categories {
         //       display: grid;
         //       grid-template-columns: 300px 300px 300px 300px;
         //   }
        }
    }
}



.viewAllBtnOfLandingPage{
    margin-left: 84%;
    margin-bottom: -175px;
}


@media screen and (max-width: 1545px) {
    .viewAllBtnOfLandingPage{
        margin-left: 85%;
    }
}



@media screen and (max-width: 1484px) {
    .viewAllBtnOfLandingPage{
        margin-left: 87%;
    }
}
