.resumeBox {
    box-shadow: 3px 0 8px -3px rgba(233, 233, 250, 0.808), /* Right */ -3px 0 8px -3px rgba(233, 233, 250, 0.808), /* Left */ 0 3px 8px -3px rgba(233, 233, 250, 0.808), /* Bottom */ 0 -3px 8px -3px rgba(233, 233, 250, 0.808);
    background: #ffffff;
    border-radius: 15px;
    border: "none";
}

.stepsonly {
    display: flex;
    align-items: center;

    padding-top: 35px;
    padding-bottom: 15px;

    width: 470px;

    .steppping {
        background: #4b92c8;
        padding: 22px;
        border-radius: 40px;

        position: relative;
    }
    .steppping::after {
        content: "";
        position: absolute;
        width: 190%;
        height: 2px;
        background: #e4e7e9;
        bottom: 0;
        margin-bottom: 30px;
        left: 55;
        margin-left: 25px;
    }
    .stp1::after {
        content: "";
        position: absolute;
        width: 185%;
        height: 2px;
        background: #4b92c8;
        bottom: 0;
        margin-bottom: 30px;
        left: 55;
        margin-left: 22px;
    }

    .stp2::after {
        content: "";
        position: absolute;
        width: 210%;
        height: 2px;
        background: #4b92c8;
        bottom: 0;
        margin-bottom: 30px;
        left: 55;
        margin-left: 22px;
    }
    .steppp::after {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        background: white;
        bottom: 0;
        margin-bottom: 30px;
        left: 55;
        margin-left: 39px;
    }
    .steppping.active {
        color: #4b92c8;
        background-color: white;
    }
}

.stepso {
    display: flex;
    padding-bottom: 15px;
    width: 470px;
    font-family: "Edu SA Beginner", cursive;
}
.step-icons {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #484848;
    margin-left: 410px;

    padding-bottom: 45px;
}

.step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #484848;
    margin-right: 75px;
}

.step-icon.active {
    color: #484848;
}

.step-content {
    padding-bottom: 70px;
    .steps {
        font-size: 14px;
        background: #4b92c8;
        border-radius: 10px;
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 8px;
        padding-bottom: 8px;

        color: white;
    }
    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 22px;
        color: #484848;
    }

    .sectionn {
        background: #ffffff;

        .section1 {
            box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.23);
            border-radius: 8px;
            padding: 20px;
            @media screen and (max-width: 768px) {
                padding: 15px;
            }
            .checkeclass {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                color: #8a8a8a;
            }
        }
        label {
            font-style: normal;
            font-weight: 450;
            font-size: 13px;
            line-height: 22px;
            color: #484848;
        }
        input::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
        }
    }
}

.personal-details {
    .details-box {
        width: 480px;
        margin-left: auto;
        margin-right: auto;
        padding: 2rem;
        border: 1px solid rgba(0, 0, 0, 0.2);

        .title {
            text-align: center;
        }

        .form-box {
            padding: 3rem 0;

            .input-box {
                padding: 2rem 0;
                display: flex;
                gap: 1rem;
                align-items: center;
                justify-content: space-between;
                font-size: 1.4rem;
                letter-spacing: 0.5px;
                font-weight: 500;

                .postalCode {
                    width: 60px;
                    margin-right: 10px;
                }

                .phoneNumber {
                    width: 230px;
                }

                input {
                    width: 300px;
                    padding: 0.6rem 1rem;
                    outline: none;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                }
            }

            .name-box {
                display: flex;
                gap: 1rem;
                font-size: 1.4rem;
                letter-spacing: 0.5px;
                font-weight: 500;
                padding: 2rem 0;

                div {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    width: 100%;

                    input {
                        padding: 0.6rem 1rem;
                        outline: none;
                        width: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                    }
                }
            }

            .submit-box {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.popup-containernew {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
.close-button {
    position: absolute;
    font-weight: bold;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    // border: 0.5px solid black;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);

    width: 30px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
}
.message-container {
    margin-bottom: 20px;
}
.error-message {
    color: red;
}

.success-message {
    color: green;
}
.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.button-container button {
    padding: 5px 8px;
    background-color: #4b92c8;
    color: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);

    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 1px;
}

.button-container button:last-child {
    margin-right: 0;
}

@media screen and (max-width: 540px) {
    .multistep-form {
        display: none;
    }
    .personal-details {
        .details-box {
            width: 100%;

            .form-box {
                .input-box {
                    flex-direction: column;
                    align-items: flex-start;

                    div {
                        display: flex;
                        width: 100%;
                    }

                    .postalCode {
                        width: 40px;
                    }

                    .phoneNumber {
                        width: 100%;
                    }

                    input {
                        width: 100%;
                    }
                }
            }
        }
    }
}
