
.imgofNav{
  height: 50px;
}

.scroll-container {
  overflow-x: hidden;
}

.scroll {
  max-width: 1224px;
  // margin: 0 auto;
  // gap: 24px;
  overflow: hidden;
  // display: flex;
  // -webkit-box-align: stretch;
  // -ms-flex-align: stretch;
  // align-items: stretch;
  // overflow-x: auto;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
}

.arrow {
  width: 40px;
  height: 40px;
  border-radius: 99px;
  border: 1.25px solid #ddd;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.track {
  height: 6px;
  width: 120px;
  background-color: #eee;
  border-radius: 99px;
  margin: 0 20px;
  position: relative;
}
.track .thumb {
  position: relative;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #008bdc;
  border-radius: 99px;
  position: absolute;
}

.main-header {
  margin-bottom: 55px;
  // margin-bottom: 91px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
}
.main-header h1 {
  font-size: 44px;
  line-height: 1.18181818;
  font-weight: 700;
  color: #333;
}

.main-header {
  position: relative;
}

.main-header img {
  position: absolute;
  // width: 0; /* Initially set width to 0 */
  // width: 18%; /* Initially set width to 0 */
  width: 37%; /* Initially set width to 0 */
  // height: 60px;
  // height: 145px;
  // height: 65px;
  height: 132px;
  // top: 100%;
  // top: 35%;
  // top: 76%;
  top: 38%;
  // left: 40%;
  background-position: -29px 0;
  background-repeat: no-repeat;
  // animation: load-from-left 1.5s ease-in-out forwards;
}

// @keyframes load-from-left {
//   to {
//     width: 700px;
//   }
// }

.main-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 4px;
}

.main-heading h1 {
  font-size: 28px;
  line-height: 1.28571429;
  font-weight: 700;
  margin: 0;
  color: #333;
}
.main-header h4 {
  font-size: 20px;
  line-height: 1.6;
  font-weight: 400;
  color: #484848;
  margin: 0;
}

.main-heading img {
  width: 28px;
  -webkit-animation: waving-hand-animation 1.4s ease-in-out;
  animation: waving-hand-animation 1.4s ease-in-out;
  -webkit-transform: rotate(4deg);
  transform: rotate(4deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.internships-jobs-section > .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  margin-bottom: 32px;
}
.internships-jobs-section > .title h5 {
  font-size: 24px;
  line-height: 1.33333333;
  font-weight: 700;
  color: #333;
}

.internships-jobs-section > .title p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #484848;
  margin: 0;
}

.main-header {
  .main-heading {
    position: relative;
    display: flex;
    align-items: center;
    // gap: 12px;

    svg {
      animation: wave 1.3s linear 2;
    }

    @keyframes wave {
      0%,
      100% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(25deg);
      }
      50% {
        transform: rotate(0deg);
      }
      75% {
        transform: rotate(-25deg);
      }
    }
  }
}

@media screen and (max-width: 1440px) {  
  .main-header img {
    position: absolute;
    // width: 0; /* Initially set width to 0 */
    // width: 24%; /* Initially set width to 0 */
    width: 41%; /* Initially set width to 0 */
    // height: 60px;
    // height: 130px;
    // height: 65px;
    height: 132px;
    // top: 100%;
    // top: 58%;
    // top: 77%;
    top: 38%;
    // left: 40%;
    background-position: -29px 0;
    background-repeat: no-repeat;
    // animation: load-from-left 1.5s ease-in-out forwards;
  }
}

@media screen and (max-width: 1112px) {  
  .main-header img {
    position: absolute;
    // width: 0; /* Initially set width to 0 */
    // width: 24%; /* Initially set width to 0 */
    width: 50%; /* Initially set width to 0 */
    // height: 60px;
    // height: 130px;
    // height: 65px;
    height: 132px;
    // top: 100%;
    // top: 58%;
    // top: 77%;
    top: 62%;
    // left: 40%;
    background-position: -29px 0;
    background-repeat: no-repeat;
    // animation: load-from-left 1.5s ease-in-out forwards;
  }
}

@media screen and (max-width: 1024px) {  
  .main-header img {
    position: absolute;
    // width: 0; /* Initially set width to 0 */
    // width: 33%; /* Initially set width to 0 */
    width: 57%; /* Initially set width to 0 */
    // height: 60px;
    // height: 130px;
    // height: 79px;
    height: 125px;
    // top: 100%;
    // top: 58%;
    // top: 80%;
    top: 59%;
    // left: 40%;
    background-position: -29px 0;
    background-repeat: no-repeat;
    // animation: load-from-left 1.5s ease-in-out forwards;
  }
  // @keyframes load-from-left {
  //   to {
  //     width: 700px;
  //   }
  // }
  
}


@media screen and (max-width: 722px) {
  .main-header h1 {
    font-size: 30px;
    line-height: 1.18181818;
    font-weight: 500;
  }

  .main-header img {
    position: absolute;
    // width: 0; /* Initially set width to 0 */
    // width: 40%; /* Initially set width to 0 */
    width: 74%; /* Initially set width to 0 */
    // height: 60px;
    // height: 54px;
    height: 118px;
    // top: 84%;
    top: 53%;
    // left: 32%;
    background-position: -20px 0;
    background-repeat: no-repeat;
    // animation: load-from-left 1.5s ease-in-out forwards;
  }

  
}
@media screen and (max-width: 768px) {
  .main-header h1 {
    font-size: 30px;
    line-height: 1.18181818;
    font-weight: 500;
  }

  .main-header img {
    position: absolute;
    // width: 0; /* Initially set width to 0 */
    // width: 40%; /* Initially set width to 0 */
    // height: 54px;
    // top: 84%;
    width: 74%; /* Initially set width to 0 */
    height: 118px;
    top: 53%;
    // left: 32%;
    background-position: -20px 0;
    background-repeat: no-repeat;
    // animation: load-from-left 1.5s ease-in-out forwards;
  }
  // @keyframes load-from-left {
  //   to {
  //     width: 700px;
  //   }
  // }
  
}


@media screen and (max-width: 483px) {


  // .main-header img {
  //   left: auto;
  //   background-position: 0px 0;
  // }
  .main-header img {
    left: auto;
    background-position: 0px 0;
    // animation: load-from-left2 1.5s ease-in-out forwards;
    // top: 85%;
    // width: 60%;
    top: 50%;
    width: 83%;
  }
  // @keyframes load-from-left2 {
  //   to {
  //     width: 400px;
  //   }
  // }

  .main-header h1 {
    width: 300px;
    font-size: 22px;
    text-align: center;
  }
  .main-header h4 {
    font-size: 16px;
    text-align: center;

  }
  
  .handicon {
    display: none;
  }

}

@media screen and (max-width: 350px) {

  .main-header h4 {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .main-header img {
    display: none;
  }

  .imgofNav{
    height: 38px;
  }


  .main-header h1 {
    width: 200px;
    font-size: 20px;
    text-align: center;
    margin-bottom: -20px;
  }
}
.slider-image{
  display: flex !important;
  justify-content: center;
  align-items: center;
}