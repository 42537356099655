/* styles.css */

.dropdown-menu a:focus {
  outline: none;
  background-color: #4b92c3;
  color: white;
  box-shadow: none;

  .DropdownIcon {
    color: white;
  }
}

.DropdownIcon {
  color: #4b92c8;
  font-size: 20px;
}

.navbar {
  // box-shadow: rgb(17 17 26 / 10%) 0px 1px 0px !important;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 2px 0 4px rgb(175, 175, 175);

}

.login-button {
  font-size: 15px;
  height: 35px;
  color: #4b92c3;
  border-color: #4b92c3;
  width: 80px;
  padding: 0px;
  transition: background-color 0.3s;
}

//style={{ textAlign: "center", width: "100px", backgroundColor: '#4B92C8', color: "white" }}
.register-dropdown {
  text-align: center;
  width: 100px;
  background-color: #4b92c8;
  border-color: #4b92c3;
}

a.register-dropdown:hover {
  background-color: #3a87c2;
}

//style={{ backgroundColor: "#4B92C8", fontSize: "13px", color: "white" }}

.signup {
  background-color: #ffffff;
  font-size: 14px;
  color: rgb(0, 0, 0);
  margin-bottom: 0px;
  margin-top: 0px;
  border-radius: 2px;
}

.signup:hover {
  background-color: #4b92c8; /* Change background color on hover */
  color: white; /* Change text color on hover */
}

// li a:hover {
// }
.hover-text:hover {
  color: #008bdc;
  text-decoration: none;
}

.SidebarTitle{
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 60px;
  font-weight: 400;
  cursor: pointer;
}

.SidebarTitleActive{
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 60px;
  font-weight: 400;
  cursor: pointer;
  background-color: #4B92C8;
  color: white;
}

.SidebarTitle:hover{
 background-color: #4B92C8;
 color: white;
}

.SidebarTitleSub{
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  padding-left: 20px;
  margin-left: 10px;
  padding-right: 60px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.SidebarTitleSub:hover{
 background-color: #4B92C8;
 color: white;
}

.SidebarTitleSubActive{
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  padding-left: 20px;
  margin-left: 10px;
  padding-right: 60px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  background-color: #4B92C8;
  color: white;
}

.LogoutAdmin{
  color: white;
  background-color: #008bdc;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  border: none;

}

.LogoutAdmin:hover{
  background-color: #047bc0;
}


@media screen and (max-width: 992px) {
  .navigation{
    display: none;
  }
  .Admin{
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .nav2{
    display: none;
  }
  .AdminMob{
    display: none;
  }
}