@import "../../../_variables";

.help-center {
    padding-top: 3.5rem;
    display: flex;
    align-items: center;
    gap: 6rem;
    flex-direction: column;
  

    .help {
        background: #D3EAFF;
      
        background-size: cover;
        background-repeat: no-repeat;
        justify-content: center;
        padding: 9rem 2rem;
   
        &::before,
        &::after {
            content: "";
            background-size: contain; /* Adjust this value to control image size */
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 30%;
        
        }

        &::before {
            left: 0%;
            background-image: url('../../../assets/images/Helppage1.png');
            width: 35%;
            top: 23%;
        }

        &::after {
            right: 0%;
            background-image: url('../../../assets/images/Helppage2.png');
            width: 30%;
            top: 33%;
        }

        .subheading {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #006DD2;
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;

            color: #1D1D1D;
        }
        input::placeholder{
            font-size: 13px;
            background: #FFFFFF;
            border-radius: 7px;
        }
        .btnsearch{
            background: #4B92C8;
            color: white;
            font-size: 15px;
            padding-top: 13px;
            padding-bottom: 13px;
            padding-left: 38px;
            padding-right: 38px;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
        }
       
        
    }
    a:hover{
        color: black;
    }
    .optiongroup{
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        gap: 40px 15px;
        flex-wrap: wrap;
        .option{
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
            border-radius: 10px;
        }
        .option-img
        img{
            width: 100%;
        }
        .option-info{
            height: 20vh;
            padding-top: 30px;
            padding-bottom: 35px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    
}


.optiongroup .option:hover {
    background-color:#E9ECEF;
  }


@media screen and (min-width: 460px) {
    .help-center {
        .help {
            .option-info{
                height: 30vh;
            }
        }
    }
}