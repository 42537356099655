.application-details-list {

    nav {
        padding: 24px 16px;
        border-bottom: 1px solid #eee;
        background-color: white;
        margin-bottom: 0px;
    }

    nav> :first-child {
        color: #666;
        font-weight: 400;
        margin-bottom: 10px;
        font-size: 14px;
    }

    nav> :first-child>a {
        color: #666;
        text-decoration: none;
    }

    nav> :last-child {
        font-size: 24px;
        font-weight: 500;
       
    }

    nav input {
        outline: none;
        border: 1px solid #eee;
        padding: 8px;
        width: 300px;
        font-size: 14px;
        margin-right: 48px;
    }

    .section {
        display: flex;
        align-items: start;
        width: 100%;
        height: 100vh;
        background-color: #f8f8f8;

        .tabs {
            height: 100%;
            width: 255px;
            background-color: #f8f8f8;
            border-right: 1px solid #eee;
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 20px 0px;

            .side-items {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 16px;
                font-family: "Inter var", sans-serif;
                color: #484848;
                font-size: 17px;
                font-weight: 500;
                line-height: 1.5;
                text-decoration: none;
            }

            .active {
                color: #008bdc;
            }


            .side-items span {
                color: #666;
                background: #ddd;
                border-radius: 10px;
                padding: 2px 8px;
                font-size: 12px;
                line-height: 1.33;
                height: 20px;
            }

            .active span {
                color: #ffffff;
                background: #008bdc;

            }

            .side-items:last-child span {
                color: white;
                background: #ff8c00;
                border-radius: 10px;
                padding: 2px 8px;
                font-size: 12px;
                line-height: 1.33;
                height: 20px;
            }


        }

        .lists {
            height: 100%;
            width: calc(100% - (255px + 352px));
            border-right: 1px solid #eee;
            overflow: hidden;
            padding-bottom: 100px;
            
            .banner {
                margin: 10px 0px;
                padding: 11px;
                font-size: 14px;
                line-height: 1.5;
                font-weight: 400;
                background-color: #eafcff;
                border: 1px solid #8feafe;
                border-radius: 6px;
                color: #006bc2;
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            .banner> :first-child {
                font-weight: 600;
            }


            .list-container {
                background-color: white;
                margin-top: 2px;
                height: 100%;
                width: 100%;
                overflow-y: auto;
                /* padding: 24px 16px; */
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 24px 16px 200px 16px;

                .candidate-container {
                    height: fit-content;
                    width: 100%;
                    border-radius: 12px;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, .15);

                    .candidate-details {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                    }

                    .candidate-details .title {
                        text-transform: uppercase;
                        color: #666;
                        letter-spacing: 0.5px;
                        width: 115px;
                        font-size: 12px;
                        font-weight: 500;
                        font-feature-settings: "cv07" on, 'kern' off;
                        flex-shrink: 0;
                        line-height: 1.3333;
                        margin-right: 24px;
                    }

                    .candidate-details .description-main {
                        color: #484848;
                        font-size: 15px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 6px;
                        
                    }

                    .candidate-details .description-main a {
                        text-decoration: none;
                        color: #00a5ec;
                    }

                    .candidate-details .description-main button {
                        padding: 8px 16px;
                        outline: none;
                        border: none;
                        transition: all 0.5s;
                        font-weight: 500;
                        margin-left: 10px;
                        @media screen and (max-width: 500px) {
                            margin-left: 0px;
                        }
                    }

                    .candidate-details .description-main button:hover {
                        box-shadow: 0 3px 8px rgba(0, 0, 0, .15);

                    }

                    .candidate-details .description-main .description {
                        color: #484848;
                        font-size: 14px;
                        display: inline-block;
                        font-weight: 400;
                        line-height: 1.57142857;
                        display: flex;
                        align-items: center;
                        gap: 6px;
                    }

                    .candidate-details .description-main .description-light {
                        color: #8a8a8a;
                        font-size: 14px;
                        display: inline-block;
                        font-weight: 400;
                        line-height: 1.57142857;
                        display: inline-flex;
                        align-items: center;
                        gap: 6px;
                    }

                    .candidate-details> :last-child> :last-child> :last-child {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                    }
                }
            }

/* Hide scrollbar for Chrome, Safari, and Opera */
.list-container::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
}

/* Track */
.list-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

/* Handle */
.list-container::-webkit-scrollbar-thumb {
    background: #529aec; /* Color of the scrollbar handle */
    border-radius: 4px; /* Roundness of the scrollbar handle */
}

/* Hide scrollbar for IE, Edge and Firefox */
.list-container {
    scrollbar-width: thin; /* Width of the scrollbar for Firefox */
    scrollbar-color: #529aec #f1f1f1; /* Color of the scrollbar for Firefox */
}

           
        }

        .filters {
            height: 100%;
            width: 352px;
            overflow-y: auto;
            padding: 24px 48px 0 24px;
            position: static;
            background-color: rgb(255, 255, 255);
        }

        .filters::-webkit-scrollbar {
            width: 6px;
            border-radius: 4px;
            background-color: #e6e6e6;
        }
    }

    .shortcut {
        padding: 4px 16px;
        outline: none;
        transition: all 0.5s;
        font-weight: 500;
        font: 14px;
        border: 1px solid #00a5ec;
        background-color: white;
        color: #00a5ec;
    }

    .shortcut:hover {
        box-shadow: 0 3px 8px rgba(0, 0, 0, .15);
    }

    .dropdown {
        position: absolute;
        bottom: 44px;
        background-color: white;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 24px 0;
        color: #484848;
        width: 242px;
        gap: 24px;
        z-index: 1;
        right: 0;
        box-shadow: 0 2px 6px rgba(0, 0, 0, .12);
        border-radius: 6px;
        border: 0;
    }

    .dropdown span {
        padding: 0 16px;
        width: 100%;
        font-size: 14px;
        cursor: pointer;
        line-height: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .dropdown span> :first-child {
        font-size: 20px;
    }

    .dropdown span:hover {
        color: #00a5ec;
    }


    .bottom-bar {
        display: none;
        align-items: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 10001;
    }

    .bottom-bar .items {
        transition: all 0.3s;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 10px 0px;
        background-color: white;
        font-size: 16px;
        box-shadow: 0 -1px 2px rgba(0, 0, 0, .08);
    }

    .bottom-bar .items .item-title {
        color: #008bdc;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .bottom-bar .items .item-count {
        font-weight: 500;
        color: #fff;
        background: #008BDC;
        border-radius: 10px;
        padding: 2px 8px;
        font-size: 12px;
        line-height: 1.33;
        height: 20px;
    }

    .bottom-bar .items-open {
        transition: all 0.3s;
        width: 100%;
    }

    .bottom-bar .items-close {
        transition: all 0.3s;
        width: 0%;
        display: none !important;
    }

    .small-window {
        width: 100%;

        .menu {
            transition: all 0.3s;
            display: flex;
            height: auto;
            flex-direction: column;
            gap: 10px;
            background-color: white;
            color: #484848;
            font-size: 16px;
            font-weight: 600;
            padding: 32px 24px;
            width: 100%;
            position: fixed;
            bottom: 43px;
            z-index: 10001;

            .side-items {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 8px 16px;
                gap: 6px;
                font-family: "Inter var", sans-serif;
                color: #484848;
                font-size: 17px;
                font-weight: 500;
                line-height: 1.5;
                text-decoration: none;
            }

            .side-items span {
                color: #666;
                background: #ddd;
                border-radius: 10px;
                padding: 2px 8px;
                font-size: 12px;
                line-height: 1.33;
                height: 20px;
            }
        }

        .no-menu {
            transition: all 0.3s;
            height: 0px;
            display: none;
        }

        .overlayH {
            width: 100%;
            height: 100%;
            top: 0;
            position: fixed;
            opacity: 0.4;
            background-color: black;
            z-index: 10000;
        }

        .no-overlayH {
            width: 0;
            height: 0;
            display: none;
            z-index: 10001
        }


        .overlay {
            width: calc(100% - 352px);
            height: 100%;
            opacity: 0.4;
            background-color: black;
            z-index: 10002
        }

        .no-overlay {
            width: 0;
            height: 0;
            display: none;
            z-index: 10001
        }

        .filter-main {
            transition: all 0.3s;
            right: 0;
            top: 0;
            height: 100%;
            position: fixed;
            overflow-y: auto;
            width: 352px;
            z-index: 10004;
        }

        .no-filter-main {
            transition: all 0.3s;
            right: 0;
            top: 0;
            height: 100%;
            position: fixed;
            width: 0px;
            z-index: 10001;
        }

        .filters {
            height: 100%;
            width: 352px;
            overflow-y: auto;
            padding: 24px 48px 0 24px;
            position: static;
            background-color: rgb(255, 255, 255);
        }

        .filters::-webkit-scrollbar {
            width: 6px;
            border-radius: 4px;
            background-color: #e6e6e6;
        }
    }


}

.AdminSearchBoxEmployerReceived{
    overflow: auto;
    background: #FFFFFF;
    box-shadow: none;
    justify-content: end;
    justify-items: end;
    text-align: end;
    margin-right: 5px;
    margin-top: -10px;
}

.AdminSearchInputEmployerReceived{
    outline: none;
    border: 1px solid #eee;
    padding: 8px;
    width: 210px;
    font-size: 14px;
    border-radius: 5px 0 0 5px;
    min-width: 210px;
}
.AdminSearchButtonEmployerReceived{
    padding: 8px 10px 9px 10px;
    background-color: #4B92C8;
    border-radius: 0 5px 5px 0;
    border: none;
    color: white;
    font-weight: 400;
    margin-left: -2px;
    font-size: 14px;
}


@media screen and (max-width: 1255px) {

    .application-details-list {
        .section {
            .tabs {
                display: none;
            }

            .lists {
                width: 100%;
                margin: 0px auto;
            }

            .filters {
                display: none;
            }
        }

        .bottom-bar {
            display: flex;
        }


    }
}


@media screen and (max-width: 780px) {

    .application-details-list {
        .section {

            .lists {

                .list-container {
                    /* .candidate-container .candidate-details> :last-child> :last-child {
                        display: flex;
                        flex-direction: column;
                        justify-content: end;
                        gap: 10px;

                        .description-main {
                            margin-left: auto;
                        }
                    } */
                }
            }


        }

        .bottom-bar> :first-child {
            width: 70%;
        }

        .bottom-bar> :last-child {
            width: 30%;
        }

        .bottom-bar .items-open {
            transition: all 0.3s;
            width: 100%;
        }

        .bottom-bar .items-close {
            transition: all 0.3s;
            width: 0%;
            display: none !important;
        }
    }
}

@media screen and (max-width: 558px) {

    .application-details-list {
        .section {

            .lists {

                .list-container {
                    .candidate-container .candidate-details> :last-child> :not(:last-child) {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                    }
                }
            }


        }

    }
}