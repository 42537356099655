@import "../../../_variables";

.view-applications {
    .filters {
        span {
            padding: 1rem;
            border-radius: 20px;
            font-size: 1.5rem;
            border: 1px solid $PRIMARY_COLOR;
            width: 150px;
            display: inline-block;
            text-align: center;
            z-index: 1;
            cursor: pointer;
            
            &:first-child {
                transform: translateX(3rem);
            }

            &.active {
                z-index: 2;
                background-color: $PRIMARY_COLOR;
                color: white;
            }
        }
    }
}