@import "../../_variables";


.borderremove{

    box-shadow: 3px 0 8px -3px rgba(233, 233, 250, 0.808),   /* Right */
    -3px 0 8px -3px rgba(233, 233, 250, 0.808),  /* Left */
    0 3px 8px -3px rgba(233, 233, 250, 0.808),  /* Bottom */
    0 -3px 8px -3px rgba(233, 233, 250, 0.808);
 
}

.post001 {
    background: #ffffff;
    border-radius: 6px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 12px;
    font-weight: bold;
    color: #4B92C8;
    border: solid 1px lightskyblue;


}

.matched-jobs {
    padding: 1rem;
    background-color: rgba(224, 220, 243, 0.212);

    .jobbox {
        .alljobpage {
            .btnsearch {
                background: #4B92C8;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 17px;
                color: #FFFFFF;
                border-radius: 5px;
                padding-left: 33px;
                padding-right: 33px;
                padding-top: 6px;
                padding-bottom: 6px;

                transition: background-color 0.3s ease;
                /* Add a transition for a smooth hover effect */

                &:hover {
                    background: #3571a9;
                    /* Change the background color on hover */
                }
            }

        }

        .alljobdetails {
            .titles {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #4F4F4F;
            }

            .cards {
                .card {
                    background: #FFFFFF;
                    border: 1px solid #EBEBEB;
                    box-shadow: 0px 2px 5px rgba(221, 222, 250, 0.808);
                    border-radius: 5px;

                    .heeading {
                        text-transform: uppercase;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 17px;
                        color: #8C8C8C;
                    }
                    .jobtitle{
                        font-size: 19px;
                        @media screen and (max-width: 768px) {
                            font-size: 16px;
                        }
                    }

                    .head {
                        margin-top: 7px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 17px;
                        color: #4F4F4F;
                    }

                    .cardbutton {

                        margin-top: 15px;
                        margin-bottom: 5px;
                        .btnview1 {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            color: #1483eb;
                            background: #FFFFFF;
                            border: none;
                            border-radius: 5px;
                            padding-left: 15px;
                            padding-right: 15px;
                            transition: background-color 0.3s ease;
                            /* Add a transition for a smooth hover effect */

                            &:hover {
                                color: #0d6dc7;
                                /* Change the background color on hover */
                            }

                        }

                        .btnapply {
                            background: #2ba4f5;
                            border-radius: 3px;
                            color: white;
                            padding-left: 15px;
                            padding-right: 15px;
                            padding-top: 6px;
                            padding-bottom: 6px;
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.3px;
                            &:hover {
                                background: #288acb;
                                color: white; 
                            }

                        }
                    }
                }

                .cname {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #4F4F4F;
                }

                .sub {
                    .name {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 17px;
                        color: #8C8C8C;
                    }

                    .type {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        color: #9E7357;
                        background: #FFFAEB;
                        border-radius: 5px;
                        padding-top: 2px;
                        padding-bottom: 2px;
                        padding-left: 12px;
                        padding-right: 12px;
                    }

                    .time {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        color: #459E25;
                        background: #EAFFD5;
                        border-radius: 5px;
                        padding-top: 2px;
                        padding-bottom: 2px;
                        padding-left: 12px;
                        padding-right: 12px;
                    }

                    .worktype {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        color: #DE5AFF;
                        background: #F9E3FF;
                        border-radius: 5px;
                        padding-top: 2px;
                        padding-bottom: 2px;
                        padding-left: 12px;
                        padding-right: 12px;
                    }

                }
            }
        }

        padding-top: 20px;
        padding-bottom: 30px;

        .filterbox {
            position: sticky;
            top: 100px; // Adjust this value based on your header's height
            overflow-y: auto;
            overflow-x: hidden;
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
           // box-shadow: 0px 2px 5px rgba(221, 222, 250, 0.808);
           
           padding-bottom: 10px;
           padding-left: 22px;
           padding-right: 22px;
            scrollbar-width: thin; /* For Firefox */
            scrollbar-color: rgb(205, 204, 204) rgb(234, 247, 255); /* For Firefox */

    /* WebKit (Chrome, Safari) scrollbar styles */
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(212, 212, 212);
        border-radius: 0px;
    }

    &::-webkit-scrollbar-track {
        background-color: rgb(246, 246, 246);
        border-radius: 0px;
    }


            .filterboxoption {
                input[type="range"] {
                    -webkit-appearance: none;
                    width: 100%;
                    height: 2px;
                    border-radius: 5px;
                    background-color: #4B92C8;
                    outline: none;
                }

                input[type="range"]::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background-color: #4B92C8;
                    cursor: pointer;
                }

                input[type="range"]::-moz-range-thumb {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: #4B92C8;
                    cursor: pointer;
                }

                input::placeholder {
                    font-size: 12px;
                }

                label {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;
                    color: #4F4F4F;
                }

                .form-check-label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: #8C8C8C;
                }
            }
        }

        .input-box {
            position: relative;

            input {
                background: #FFFFFF;
                border: 1px solid #C7C7C7;
                border-radius: 5px 0px 0px 5px;
                padding: 1rem 3.4rem;
                width: 100%;
                outline: none;
            }

            svg {
                &:first-child {
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin: 1.3rem .8rem;
                }
            }
        }
    }

    .title {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .filter-icon,
    .close_btn {
        display: none;
    }

    .jobs-box {
        display: flex;
        justify-content: center;
        gap: 5rem;
        padding-top: 4rem;

        .filters-box {
            background-color: #EFEFEF;
            padding: 3rem;
            max-height: 600px;
            overflow: auto;

            .filter-title {
                display: flex;
                justify-content: center;
                gap: 1rem;
            }

            .filters {
                padding: 3rem 0;

                .filter {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    padding: .5rem 0;
                }
            }

            .input-box {
                padding: 1.5rem 0;

                label {
                    display: block;
                    padding-bottom: .5rem;
                    font-size: 1.5rem;
                }

                input {
                    width: 100%;
                    padding: .8rem 1rem;
                    outline: none;
                    border: 1px solid #ccc;
                }
            }
        }

        .jobs {
            .job-card {
                margin-bottom: 3rem;
                padding: 2rem;
                width: 550px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                box-shadow: 1px 2px 20px rgba(151, 151, 151, 0.2);

                .company-title {
                    padding: 1rem 0;
                    font-size: 1.8rem;
                }

                .job-title {
                    font-weight: 500;
                    color: #8A8A8A;
                    padding-bottom: 2.5rem;
                    font-size: 1.5rem;
                }

                .info-box {
                    display: flex;
                    gap: 3rem;
                    padding-bottom: 3rem;

                    .info {
                        display: flex;
                        flex-direction: column;
                        gap: .8rem;

                        span {
                            font-size: 1.4rem;
                        }
                    }
                }

                .options {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 2rem;
                    padding-top: 1.5rem;
                    border-top: 1px solid rgba(0, 0, 0, 0.1);

                    * {
                        color: $PRIMARY_COLOR;
                    }
                }
            }
        }
    }
}


.RowMargin{
    margin-left: 40px;
}


.OrgLogoAvatar{
width: auto;
height: 30px;
max-width: 90px;
}

@media screen and (max-width: 950px) {
    .matched-jobs {
        .title {
            position: relative;
        }

        .filter-icon {
            display: block;
            position: absolute;
            right: 0;
            top: -4rem;
            cursor: pointer;
        }

        .close_btn {
            display: block;
            position: absolute;
            top: 1rem;
            right: 2rem;
            cursor: pointer;
        }

        .jobs-box {
            .filters-box {
                position: fixed;
                width: 95%;
                height: 90vh;
                display: none;
                top: 0;
                left: 0;
                min-height: 95vh;
                margin: 2rem;

                &.open {
                    display: block;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {

    .OrgLogoAvatar{
        max-width: 70px;
        }
        

    .RowMargin{
        margin-left: -10px;
    }
    .matched-jobs {
        .jobs-box {
            .jobs {
                width: 100%;

                .job-card {
                    width: 100%;
                }
            }
        }
    }

    .btnview {

        margin-bottom: 10px;
    }

    .worktype {
        margin-top: 10px;

    }

}

/* Pagination Styles */

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        padding: 8px 12px;
        margin: 0 4px;
        background-color: #4B92c8;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: #3571a9;
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }

    span {
        margin: 0 10px;
        font-weight: bold;
        font-size: 14px;
        color: #333;
    }
}

// .fixed-header {
//     position: sticky;
//     top: 60px;
//     left: 547px;
//     right: 107.5px;
//     background-color: rgb(255, 255, 255);
//     z-index: 1000;
//     padding: 20px;
//     box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
//     border-radius: 5px;
//     max-height: calc(100vh - 200px);
//     overflow-y: auto;
//     overflow-x: hidden
// }

// .content-below-header {
//     margin-top: 100px;
// }

.apply-margin {
    margin-left: 140px; 
  }
  


  .paginationBtn {
    cursor: pointer;
    color: rgb(92, 168, 255); /* Default color for enabled state */
  }
  
  .paginationBtndisabled {
    color: gray; /* Color for disabled state */
  }
  

  .clearall{
    color: #4B92c8;
    font-weight: 500;
    font-size: 15px;
    text-align: end;
    cursor: pointer;
    margin-top: 20px;
  }


  .clearall:hover{
    color: #157bc8;
  }

.searchbtnoffilter{
    background: #2ba4f5;
    border: none;
    padding-left: 14px;
    padding-right: 14px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 43px;

}

.searchbtnoffilter:hover{
    background: #2d95da;

}

.ctrl22::placeholder {
    font-size: 14px !important; /* Adjust the font size as needed */
    color: #999999 !important;
    font-weight:400 !important;
    letter-spacing: 0.3px !important;
    font-style: normal !important;
}

.ctrl22 input{
    width: 100%;
    outline: none;
    box-shadow: none;
    background-color: white;
    color: #484848;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 8px 12px;
}

.ctrl22 input:hover,
.ctrl22 input:focus {
    border: 1px solid #00a5ec;
}

.ctrl22 {
margin-top: 15px;
.filterselect{
border:  solid 1px rgb(205, 205, 205);
border-radius: 3px;
padding-top: 1px;
padding-bottom: 1px;
padding-right: -15px;
}
}


.ctrl22 .filterselect:hover,
.ctrl22 .filterselect:focus {
    border: 1px solid #00a5ec;
}

@media (min-width: 760px) {
    .adjustfilterboxheight {
        margin-bottom: 48px;
    }
    .adjustfilterboxheight2 {
        margin-bottom: 34px;
    }
}