.EmployeBuyButton {
    padding: 8px 10px 9px 10px;
    background-color: #3aad5d;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: 400;
    /* margin-left: -2px; */
    /* width: 207px; */
    text-align: center;
    margin-top: 80px;
    margin-left: auto;
}

.usdamountstyle {
    background-color: #edf3fa;
    border: 1px solid #ddd;
    outline: none;
    padding: 7px;
    border-radius: 6px;
}

.my-applications-buyconnects {
    background-color: white !important;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    padding: 6rem 1rem;
    min-height: 60vh;
    justify-content: center; /* centers horizontally */
    align-items: center;
}
.buyconnectsbuttons {
    display: flex;
    gap: 13px;
    margin-left: 71px;
}
.EmployeeCancelButton {
    padding: 8px 10px 9px 10px;
    background-color: #eee;
    border-radius: 10px;
    border: none;
    color: #707070;
    font-weight: 400;
    text-align: center;
    margin-top: 80px;
}

.buyconnectmaindiv {
    padding-top: 7rem;
    padding-bottom: 4rem;
    width: 500px;
}

@media screen and (max-width: 574px) {
    .buyconnectmaindiv {
        width: 320px;
        padding-top: 3rem;
        padding-bottom: 2rem;
    }
}

.myapplicationsbuymain {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 60vh; */
    min-height: 60vh;
    padding: 20px;
}
.my-applicationsbuyconnects {
    background-color: #edf3fa; /* Background color */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
    border-radius: 8px; /* Border radius */
    /* padding: 20px; */
    /* padding: 0px 0px 20px 0px; */
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: min-content;
}
.my-applicationsbuyconnects2 {
    background-color: #edf3fa; /* Background color */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
    border-radius: 8px; /* Border radius */
    /* padding: 20px; */
    /* padding: 0px 0px 20px 0px; */
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: max-content;
}
.my-applicationsbuyconnectsheader {
    height: 90px;
    background-color: #edf3fa;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.my-applicationsbuyconnectsbody {
    background-color: #f8f9fa; /* Background color */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px;
    /* padding: 0px 0px 20px 0px; */
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 400px) {
        width: 300px;
        align-items: center;
    }
}
.my-applicationsbuyconnectsbodyinformation {
    background-color: #f8f9fa; /* Background color */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px;
    /* padding: 0px 0px 20px 0px; */
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.selectspan {
    display: flex;
    gap: 10px;
    /* background-color: #F8F8F8; */
    /* border: 1px solid #ddd; */
    border-radius: 6px;
    width: max-content;
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.employerbuttontobuy {
    display: flex-end;
    background-color: #3aad5d;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: 400;
    align-self: center;
    padding: 8px 10px 9px 10px;
    text-align: center;
}

.employerbuttontobuyinformation {
    display: flex-end;
    background-color: #3aad5d;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: 400;
    align-self: flex-end;
    padding: 8px 10px 9px 10px;
    text-align: center;
}

.employerbuttonbacktodash {
    display: flex-end;
    background-color: #edf3fa;
    border-radius: 7px;
    border: none;
    font-weight: 400;
    align-self: center;
    padding: 8px;
    text-align: center;
}
