// @import './fonts.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
// @tailwind base;
// @tailwind components;
// @tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Roboto', sans-serif;
  // color: #333;
  --toastify-toast-min-height: 30px;
  --toastify-toast-max-height: 800px;
}

html {
  font-size: 62.5%;

  body {
    font-size: 1.6rem;
    // overflow-x:hidden;
  }
}
