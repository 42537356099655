.terms-of-use{

  .heading{
    font-size: 22px !important;
    @media screen and (max-width: 1200px) {
      font-size: 18px !important;
    }
  }
  h3{
    font-size: 22px !important;
    @media screen and (max-width: 1200px) {
      font-size: 18px !important;
    }
  }
  h4{
    font-size: 17px !important;
    @media screen and (max-width: 1200px) {
      font-size: 15px !important;
    }
  }
}
  @media screen and (max-width: 910px) {
    .about-us-mobile-responsive {
      width: 88vw;
        text-align: justify;
    }
    
  }