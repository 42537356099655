.whiteScreen {
    padding : 10px 150px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    @media screen and (max-width: 1200px) {
        padding : 10px 50px;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
    .firstcol{
        padding : 10px;
        .supportTitle{
            font-size: 35px;
            @media screen and (max-width: 1200px) {  
                font-size: 25px;
            }   
            @media screen and (max-width: 768px) {  
                font-size: 20px;
            }
        }   
        .supportSubTitle{
            font-size: 25px;
            @media screen and (max-width: 1200px) {  
                font-size: 18px;
            }
            @media screen and (max-width: 768px) {
                font-size: 15px;
            }
        }
    }
}

.supportCard {
    box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.26);
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
    border-radius: 15px;
    background-color: white;
    @media screen and (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    // max-width: 500px;
    // min-width: 250px;
}

.cardText {
    height: 140px;
    font-size: 18px;
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}
.cardTitle{
    font-size: 32px;
    margin-top: 25px;
    margin-bottom: 17px;
    @media screen and (max-width: 768px) {
        font-size: 28px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    @media screen and (max-width: 500px) {
        font-size: 22px;
    }
}

.supportBtn {
    color: rgb(3, 122, 219);
    background: rgb(210, 237, 255);
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 25px;
    font-weight: 500;
    border-radius: 8px;
    width: 100%;
    border: none;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
        font-size: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.supportNote {
    padding-top: 30px;
    margin-top: 40px;
    margin-bottom: 30px;
    text-align: start;
    margin-left: auto;
    margin-right: auto;
    // color: rgb(160, 160, 160);
    font-weight: 400;
    font-size: 17px;
}

.supportNotenew {
    margin-top: 40px;
    margin-bottom: 30px;
    text-align: start;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    font-size: 17px;
    @media screen and (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 20px;
        
    }
}
.supportNotenew2 {
    text-align: start;
    margin-left: auto;
    margin-right: auto;
    // color: rgb(160, 160, 160);
    font-weight: 400;
    font-size: 17px;
}
.supportNote3 {
    color: "black";
    font-weight: 500;
    font-size: 17px;
}
.supportNote4 {
    color: rgb(160, 160, 160);
    font-weight: 400;
    font-size: 17px;
}

.contactUsTitle {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 15px;
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
    @media screen and (max-width: 500px) {
        font-size: 14px;
    }
}

.contactUsInput {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: solid 2px rgb(194, 194, 194);
}

.contactUsBtn {
    color: white;
    background-color: #0077e4;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 10px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    @media screen and (max-width: 768px) {
        font-size: 16px;
        padding-left: 30px;
        padding-right: 30px;
    }
    @media screen and (max-width: 500px) {
        font-size: 14px;
    }
}

.contactUsBtn:hover {
    background-color: #166bbb;
}

.firstcol {
    min-width: 300px;
}
.secondcol {
    text-align: end;
}

@media screen and (max-width: 882px) {
    .whiteScreen {
        padding-left: 0px;
        padding-right: 0px;
    }
    .firstcol {
        text-align: center;
    }
    .secondcol {
        text-align: center;
    }
}

@media screen and (max-width: 933px) {
    .cardText {
        height: 100px;
    }
}

@media screen and (max-width: 586px) {
    .cardText {
        height: auto;
    }
}
