.dialog {


    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;

    .dialog-container {
        width: 600px;
        display: flex;
        flex-direction: column;
        padding: 32px 28px;
    }


    .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        gap: 6px;

    }

    .title > :last-child {
        margin-left: auto;
    }

    .candidate-list {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        color: #484848;
        font-weight: 500;
        margin-bottom: 12px;
        font-size: 14px;
    }

    .candidate-list> :not(:first-child) {
        background-color: #ddd;
        color: #333;
        font-weight: 400;
        padding: 4px 8px;
        border-radius: 3px;
        display: inline-block;
    }

    form {
        display: flex;
        flex-direction: column;
        width: 100%;

        textarea {
            margin-top: 16px;
            position: relative;
            width: 100%;
            resize: none;
            overflow-y: auto;
            padding: 10px 43px 6px 10px;
            height: 200px;
            overflow-x: hidden;
            border: 1px solid #ddd;
            outline: none;
        }

        textarea::placeholder {
            font-size: 14px;
            color: #8a8a8a;
        }

        textarea:hover,
        textarea:focus {
            border: 1px solid #00a5ec;
        }

        span {
            margin-top: 8px;
            font-size: 12px;
            text-align: justify;
            line-height: 1.3333;
            color: #8a8a8a;
            font-weight: 400;
        }

        button {
            align-self: flex-end;
            margin-top: 16px;
            color: white;
            font-weight: 600;
            background-color: #00a5ec;
            border-radius: 3px;
            border: 1px solid #00a5ec;
            padding: 4px 15px;
            min-width: 80px;
            font-size: 14px;
        }
    }
    .spinner {
        animation: spin 1s linear infinite;
        font-size: 18px; /* Adjust size */
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      
      .send-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px; /* Space between text and icon */
        width: max-content;
        height: 40px;
        background-color: #00a5ec;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
      }
      
      .send-btn:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
      

    @media screen and (max-width: 1255px) {

        .dialog-container {

            width: 450px;
        }
    }

    @media screen and (max-width: 770px) {

        .dialog-container {

            width: 100%;
        }
    }
}

.spinner {
    animation: spin 1s linear infinite;
    font-size: 18px; /* Adjust size */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .send-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px; /* Space between text and icon */
    width: 150px;
    height: 40px;
    background-color: #00a5ec;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .send-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  